import { CATEGORY_POSITION, CATEGORY_ADD, CATEGORY_DELETE, FETCH_CATEGORY } from '../constants';

export default (state = null, action) => {
  switch (action.type) {
    case CATEGORY_POSITION:
      return action.payload;
    case CATEGORY_ADD:
      return action.payload;
    case CATEGORY_DELETE:
      return action.payload;
    case FETCH_CATEGORY:
      return action.payload;
    default:
      return state;
  }
};
