import { MUNICIPALITIES_LIST } from '../constants';

export default (state = [], action) => {
  switch (action.type) {
    case MUNICIPALITIES_LIST:
      return action.payload['hydra:member'];
    default:
      return state;
  }
};
