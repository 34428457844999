import { AD_MEDIA_RELATED } from '../constants';

export default (state = {}, action) => {
  const newState = {...state};
  switch (action.type) {
    case AD_MEDIA_RELATED:
      if(!newState[action.type.subType])
        newState[action.type.subType] = [];
      newState[action.type.subType] = [...newState[action.type.subType], action.payload];
      return newState;
    default:
      return state;
  }
};
