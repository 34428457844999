import { SET_PAGE_NUMBER, SET_PAGE_ITEMS } from '../constants';

const initialState = {
  page: 1,
  perPage: 10,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGE_NUMBER:
      return { ...state, page: action.payload };
    case SET_PAGE_ITEMS:
      return { ...state, perPage: action.payload };
    default:
      return state;
  }
};
