/* eslint-disable global-require */

// The top-level (parent) route
const routes = {
  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '',
      load: () => import(/* webpackChunkName: 'home' */ './home'),
    },
    {
      path: '/page-:page',
      load: () => import(/* webpackChunkName: 'home' */ './home'),
    },
    {
      path: '/register',
      load: () => import(/* webpackChunkName: 'register' */ './register'),
    },
    {
      path: '/activate',
      load: () => import(/* webpackChunkName: 'activate' */ './activate'),
    },
    {
      path: '/forgotten',
      load: () => import(/* webpackChunkName: 'forgotten' */ './forgotten'),
    },
    {
      path: '/forgotten_token',
      load: () =>
        import(/* webpackChunkName: 'forgotten_token' */ './forgotten_token'),
    },
    {
      path: '/login',
      load: () => import(/* webpackChunkName: 'login' */ './login'),
    },
    {
      path: '/logout',
      load: () => import(/* webpackChunkName: 'logout' */ './logout'),
    },
    {
      path: '/publish',
      load: () => import(/* webpackChunkName: 'publish' */ './publish'),
    },
    {
      path: '/ad-edit',
      load: () => import(/* webpackChunkName: 'ad-edit' */ './adEdit'),
    },
    {
      path: '/category-edit',
      load: () =>
        import(/* webpackChunkName: 'category-edit' */ './categoryEdit'),
    },
    {
      path: '/search',
      load: () => import(/* webpackChunkName: 'search' */ './search'),
    },
    // API dynamic routes
    {
      path: 'API-ad',
      load: () => import(/* webpackChunkName: 'API-ad' */ './adView'),
    },
    {
      path: 'API-category',
      load: () =>
        import(/* webpackChunkName: 'API-category' */ './categoryView'),
    },
    // Static pages
    {
      path: '/about',
      load: () => import(/* webpackChunkName: 'about' */ './about'),
    },
    {
      path: '/cookies',
      load: () => import(/* webpackChunkName: 'cookies' */ './cookies'),
    },
    {
      path: '/contact',
      load: () => import(/* webpackChunkName: 'contact' */ './contact'),
    },
    {
      path: '/account',
      load: () => import(/* webpackChunkName: 'account' */ './account'),
    },
    {
      path: '/confidentiality',
      load: () => import(/* webpackChunkName: 'confidentiality' */ './confidentiality'),
    },
    {
      path: '/terms',
      load: () => import(/* webpackChunkName: 'terms' */ './terms'),
    },
    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
    },
  ],

  async action({ next }) {
    // Execute each child route until one of them return the result
    const route = await next();

    // Provide default values for title, description etc.
    route.title = `${route.title || 'Untitled Page'} - ZSL.bg`;
    route.description = route.description || '';

    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./error').default,
  });
}

export default routes;
