/* eslint-disable import/prefer-default-export */

import { GET_ROUTE, CLEAR_ROUTE } from '../constants';

export const getRoute = path => async (dispatch, getState, { axios }) => {
  const response = await axios.get(
    `/routes?path=${path.replace(/^\/+|\/+$/g, '')}`,
  );
  dispatch({
    type: GET_ROUTE,
    payload: response.data,
  });
};

export const clearRoute = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ROUTE,
    payload: null,
  });
};
