/* eslint-disable import/prefer-default-export */

import {
  USER_LOGIN,
  USER_REGISTER,
  USER_LOGIN_CLEAR,
  FETCH_USER,
  ACTIVATE,
  FORGOTTEN,
  USER_UPDATE,
} from '../constants';

export const register = data => async (dispatch, getState, { axios }) => {
  const response = await axios.post(`/register`, data);
  dispatch({
    type: USER_REGISTER,
    payload: response.data,
  });
};

export const activate = token => async (dispatch, getState, { axios }) => {
  try {
    const response = await axios.post(`/auth/activate`, { token });
    dispatch({
      type: ACTIVATE,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ACTIVATE,
      payload: e.response.data,
    });
  }
};

export const updateProfile = (id, data) => async (
  dispatch,
  getState,
  { axios },
) => {
  try {
    const response = await axios.put(`/users/${id}`, data);
    dispatch({
      type: USER_UPDATE,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: USER_UPDATE,
      payload: e.response.data,
    });
  }
};

export const forgotten = data => async (dispatch, getState, { axios }) => {
  try {
    const response = await axios.post(`/auth/forgotten`, data);
    dispatch({
      type: FORGOTTEN,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: FORGOTTEN,
      payload: e.response.data,
    });
  }
};

export const forgottenToken = data => async (dispatch, getState, { axios }) => {
  try {
    const response = await axios.post(`/auth/forgotten_token`, data);
    dispatch({
      type: FORGOTTEN,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: FORGOTTEN,
      payload: e.response.data,
    });
  }
};

export const login = data => async (dispatch, getState, { axios }) => {
  try {
    const response = await axios.post(`/login`, data);
    dispatch({
      type: USER_LOGIN,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: USER_LOGIN,
      payload: e.response.data,
    });
  }
};

export const logout = () => async (dispatch, getState, { axios }) => {
  const response = await axios.get(`/logout`);
  dispatch({
    type: FETCH_USER,
    payload: response.data,
  });
};

export const clearLogin = () => async dispatch => {
  dispatch({
    type: USER_LOGIN_CLEAR,
    payload: null,
  });
};

export const fetchUser = () => async (dispatch, getState, { axios }) => {
  const response = await axios.get(`/auth/me`);
  dispatch({
    type: FETCH_USER,
    payload: response.data,
  });
};

export const fetchTest = () => async (dispatch, getState, { axios }) => {
  const response = await axios.get(`/server/test`);
  dispatch({
    type: 'test',
    payload: response.data,
  });
};

export const setLoginData = data => async dispatch => {
  dispatch({
    type: USER_LOGIN,
    payload: data,
  });
};
