import { combineReducers } from 'redux';
import router from './router';
import user from './user';
import ad from './ad';
import media from './media';
import mediaRelated from './mediaRelated';
import adList from './adList';
import adListRelated from './adListRelated';
import categories from './categories';
import category from './category';
import total from './total';
import pagination from './pagination';
import adFilter from './adFilter';
import areas from './areas';
import municipalities from './municipalities';
import places from './places';
import placeSearch from './placeSearch';
import contact from './contact';
import subscribe from './subscribe';

export default combineReducers({
  router,
  user,
  ad,
  media,
  mediaRelated,
  adList,
  adListRelated,
  categories,
  category,
  total,
  pagination,
  adFilter,
  areas,
  municipalities,
  places,
  placeSearch,
  contact,
  subscribe
});
