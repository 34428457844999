/* eslint-disable import/prefer-default-export */

import { SET_FILTER_CATEGORY } from '../constants';

export const setFilterCategory = (id) => async dispatch => {
  dispatch({
    type: SET_FILTER_CATEGORY,
    payload: id,
  });
};
