import {
  USER_LOGIN,
  USER_LOGIN_CLEAR,
  USER_REGISTER,
  USER_UPDATE,
  FETCH_USER,
  ACTIVATE,
  FORGOTTEN,
} from '../constants';

const defaultState = {
  userRegister: null,
  userLogin: null,
  data: null,
  activate: null,
  forgotten: null,
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case USER_REGISTER:
      return { ...state, userRegister: action.payload };
    case USER_LOGIN:
      return { ...state, userLogin: action.payload };
    case FETCH_USER:
      return { ...state, data: action.payload };
    case USER_UPDATE:
      return { ...state, data: action.payload };
    case USER_LOGIN_CLEAR:
      return { ...state, userLogin: null };
    case ACTIVATE:
      return { ...state, activate: action.payload };
    case FORGOTTEN:
      return { ...state, forgotten: action.payload };
    default:
      return state;
  }
};
