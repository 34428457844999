import { AD_LIST, CLEAR_AD_LIST, CLEAR_LIST_ALL, AD_DELETE } from '../constants';

const initialState = {
  data: [],
  param: null,
  total: null,
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case AD_LIST:
      return {
        data: action.payload['hydra:member'],
        param: action.payload['hydra:view'],
        total: action.payload['hydra:totalItems'],
      };
    case CLEAR_AD_LIST:
      return {
        ...state,
        data: [],
      };
    case CLEAR_LIST_ALL:
      return { ...initialState };
    case AD_DELETE:
      const newList = state.data.filter(ad => ad['@id'] !== action.payload);
      return {
        ...state,
        data: newList,
      };
    default:
      return state;
  }
};
