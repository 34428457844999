import { AREAS_LIST } from '../constants';

export default (state = [], action) => {
  switch (action.type) {
    case AREAS_LIST:
      return action.payload['hydra:member'];
    default:
      return state;
  }
};
