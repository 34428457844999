import { TOTAL_AD } from '../constants';

const initialState = {
  ad: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOTAL_AD:
      return {
        ...state,
        ad: action.payload['hydra:totalItems']
      };
    default:
      return state;
  }
};
