/* eslint-disable import/prefer-default-export */

import { SET_PAGE_NUMBER } from '../constants';

export const setPage = (page) => async dispatch => {
  dispatch({
    type: SET_PAGE_NUMBER,
    payload: page,
  });
};
