/* eslint-disable import/prefer-default-export */

export const GET_ROUTE = 'GET_ROUTE';
export const CLEAR_ROUTE = 'CLEAR_ROUTE';
export const USER_REGISTER = 'USER_REGISTER';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_CLEAR = 'USER_LOGIN_CLEAR';
export const FETCH_USER = 'FETCH_USER';
export const USER_UPDATE = 'USER_UPDATE';

export const AD_CREATE = 'AD_CREATE';
export const AD_CLEAR = 'AD_CLEAR';
export const AD_MEDIA = 'AD_MEDIA';
export const AD_MEDIA_RELATED = 'AD_MEDIA_RELATED';
export const AD_LIST = 'AD_LIST';
export const AD_LIST_RELATED = 'AD_LIST_RELATED';
export const AD_DELETE = 'AD_DELETE';
export const CLEAR_AD_LIST = 'CLEAR_AD_LIST';
export const CLEAR_LIST_ALL = 'CLEAR_LIST_ALL';
export const GET_MEDIA = 'GET_MEDIA';
export const CLEAR_MEDIA = 'CLEAR_MEDIA';

export const CATEGORIES_LIST = 'CATEGORIES_LIST';
export const CATEGORY_ADD = 'CATEGORY_ADD';
export const CATEGORY_POSITION = 'CATEGORY_POSITION';
export const CATEGORY_DELETE = 'CATEGORY_DELETE';

export const AREAS_LIST = 'AREAS_LIST';
export const MUNICIPALITIES_LIST = 'MUNICIPALITIES_LIST';
export const PLACES_LIST = 'PLACES_LIST';
export const PLACE_SEARCH = 'PLACE_SEARCH';

export const TOTAL_AD = 'TOTAL_AD';

export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const SET_PAGE_ITEMS = 'SET_PAGE_NUMBER';

export const SET_FILTER_CATEGORY = 'SET_FILTER_CATEGORY';
export const SET_FILTER_PRICE = 'SET_FILTER_PRICE';

export const FETCH_CATEGORY = 'FETCH_CATEGORY';

export const ACTIVATE = 'ACTIVATE';
export const FORGOTTEN = 'FORGOTTEN';

export const CONTACT = 'CONTACT';
export const SUBSCRIBE = 'SUBSCRIBE';

export const USER_ROLES = {
  ROLE_ADMIN: null,
  ROLE_EDITOR: null,
  ROLE_PUBLISHER: null,
  ROLE_USER: null,
};
