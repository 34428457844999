import { PLACE_SEARCH } from '../constants';

export default (state = [], action) => {
  switch (action.type) {
    case PLACE_SEARCH:
      return action.payload['hydra:member'] ? action.payload['hydra:member'] : [];
    default:
      return state;
  }
};
