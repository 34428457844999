import {setPage} from "../actions/pagination";
import {setFilterCategory} from "../actions/filter";

export async function setPathFilters(context) {
  const { query } = context;
  const state = context.store.getState();
  const { router } = state;

  if (query.page && query.page > 0) {
    await context.store.dispatch(setPage(query.page));
  } else {
    await context.store.dispatch(setPage(1));
  }

  if (router) { //Dynamic page (Category)
    const {referenceObject, referenceId} = router;

    switch (referenceObject.name) {
      case 'category':
        await context.store.dispatch(setFilterCategory(referenceId));
        break;
      default:
        await context.store.dispatch(setFilterCategory(null));
    }
  } else { // Static page (Home)
    await context.store.dispatch(setFilterCategory(null));
  }
}
