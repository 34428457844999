import { AD_LIST_RELATED } from '../constants';

export default (state = {}, action) => {
  const newState = {...state};
  switch (action.type.type) {
    case AD_LIST_RELATED:
      newState[action.type.subType] = action.payload['hydra:member'];
      return newState;
      // return {
      //   data: action.payload['hydra:member'],
      //   param: action.payload['hydra:view'],
      //   total: action.payload['hydra:totalItems'],
      // };
    default:
      return state;
  }
};
