import { SET_FILTER_CATEGORY, SET_FILTER_PRICE } from '../constants';

const initialState = {
  category: null,
  price: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER_CATEGORY:
      return { ...state, category: action.payload };
    case SET_FILTER_PRICE:
      return { ...state, price: action.payload };
    default:
      return state;
  }
};
