import { AD_MEDIA, CLEAR_MEDIA } from '../constants';

export default (state = [], action) => {
  switch (action.type) {
    case AD_MEDIA:
      const set = [...state, action.payload].reduce((acc, item) => {
        acc[item['@id']] = item;
        return acc;
      }, {});
      return Object.keys(set).map((k) => set[k]);
    case CLEAR_MEDIA:
      return [];
    default:
      return state;
  }
};
