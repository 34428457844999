import {CLEAR_ROUTE, GET_ROUTE} from '../constants';

export default (state = null, action) => {
  switch (action.type) {
    case GET_ROUTE:
      if (action.payload['hydra:member'].length > 0)
        return action.payload['hydra:member'][0];
      return null;
    case CLEAR_ROUTE:
      return null;
    default:
      return state;
  }
};
